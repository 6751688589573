import { usePreferences } from "@context/preferences.context";
import { I18nLanguage } from "@interfaces/models/i18n";
import { Currency } from "@interfaces/models/currency";
import { Country } from "@interfaces/models/country";
import useLocalizationQuery from "./use-localization";

const useDisplayedPreference = () => {
  const { currency: cookieCurrency, country: countryCode, locale } = usePreferences();
  const { locales, countryList, currencies } = useLocalizationQuery();

  const displayedLang: I18nLanguage = locales.find((data: I18nLanguage) => data.isoCode === locale);
  const displayedCurrency: Currency = currencies.find((data: Currency) => data.isoCode === cookieCurrency);
  const displayedCountry: Country = countryList.find((data: Country) => data.isoCode === countryCode);

  return {
    displayedLang,
    displayedCurrency,
    displayedCountry,
  };
};

export default useDisplayedPreference;
